import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Saml from './Saml';
import Home from './Home';

const Root = () => {
  return (
    <div>
      <Switch>
        <Route component={Saml} exact path="/saml" />
        <Route component={Home} path="/" />
      </Switch>
    </div>
  );
};

export default Root;
