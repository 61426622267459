import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import * as samlUtils from '../../../saml-auth';

const idp = process.env.REACT_APP_SAML_PROVIDER;
const idpEndpoint = process.env.REACT_APP_COGNITO_AUTH_URL;

class Saml extends Component {
  componentDidMount() {
    const history = createBrowserHistory();
    const historyPath = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (historyPath) {
      history.replace(historyPath);
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let clientId = params.get('client');
    let origin = params.get('origin');

    console.log('clientId: ', clientId);
    console.log('origin: ', origin);

    const getProxyRedirect = function() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    };

    let redirect = getProxyRedirect();

    samlUtils
      .samlInit(idpEndpoint, clientId, redirect, idp, origin)
      .then(resp => {
        if (!!resp) {
          console.log('resp from saml: ', resp);
        } else {
          console.log('no response from saml');
        }
      });
  }

  render() {
    return <div></div>;
  }
}

export default Saml;
