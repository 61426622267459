import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Root from './components/Root';

render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById('root')
);

// import * as saml from './saml-auth';
// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();
// const historyPath = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
// if (historyPath) {
//   history.replace(historyPath);
// }
// let path = window.location.pathname;
// let search = window.location.search;
// let params = new URLSearchParams(search);
// let clientId = params.get('client');
// let origin = params.get('origin');
// let idp;
//
// console.log('path:', path);
// console.log('clientId: ', clientId);
// console.log('COGNITO_sdaAUTH_URL: ', process.env.REACT_APP_SAML_PROVIDER);
// const getProxyRedirect = function() {
//   return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
// };
// let redirect = getProxyRedirect();
// switch (path.toLowerCase()) {
//   case '/accounts':
//     console.log('switch accounts');
//     idp = 'uim-openidc-develop';
//     break;
//   case '/saml':
//     console.log('switch saml');
//     idp = process.env.REACT_APP_SAML_PROVIDER;
//     break;
//   default:
//     console.log('no provider');
// }

// const provider = idp;
//
// const idpEndpoint = process.env.REACT_APP_COGNITO_AUTH_URL;
//
// saml.samlInit(idpEndpoint, clientId, redirect, provider, origin).then(resp => {
//   if (!!resp) {
//     console.log('resp from saml: ', resp);
//   } else {
//     console.log('no response from saml');
//   }
// });
