import Cookies from 'universal-cookie';

const cookies = new Cookies();
const AUTH_CODE_GRANT_TYPE = 'authorization_code';
const REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
const jwtDecode = require('jwt-decode');

// sets tokens to cookies
const setTokenCookies = function(idToken, refreshToken, expires_in) {
  cookies.set('id_token', idToken, { maxAge: expires_in });
  cookies.set('refresh_token', refreshToken);
};

// once initial call returns code in the querystring this function will take the
// code and exchange it for the token and return the token response
const getTokenFromResponseCode = async function(
  idpUrl,
  code,
  redirectUrl,
  clientId
) {
  console.log('attempting to get token');
  console.log('show me tha client Iddd: ', clientId);
  console.log('show me DA redirect_uri: ', redirectUrl);

  console.log('responseCode; ', code);
  const body = new URLSearchParams();
  body.append('grant_type', AUTH_CODE_GRANT_TYPE);
  body.append('client_id', clientId);
  body.append('code', code);
  body.append('redirect_uri', redirectUrl);

  const tokenResp = await fetch(`${idpUrl}oauth2/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: body,
  });
  console.log('tokenResp', tokenResp);
  const resp = await tokenResp.json();
  console.log('resp', resp);
  return resp;
  // if (!!resp.id_token) {
  //     // setTokenCookies(resp.id_token, resp.refresh_token, resp.expires_in);
  //
  // } else
  //     return {error: ''};
};

// this function will redirect the page to the initial login screen for rbna saml
export function redirectToLogin(
  idpUrl,
  clientId,
  redirectUrl,
  provider,
  path,
  origin
) {
  const url = `${idpUrl}oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&identity_provider=${provider}&scope=openid+profile`;
  const state = {
    client_id: clientId,
    path,
    redirect_url: redirectUrl,
    origin,
  };

  window.location = `${url}&state=${encodeURIComponent(JSON.stringify(state))}`;
}

// this function checks for tokens in cookies. if a token is expired it will make the call for refresh
const checkTokenForRefresh = async function(
  jwt,
  refreshToken,
  clientId,
  idpUrl
) {
  const jwtObj = jwtDecode(jwt);
  if (!!jwtObj.exp && jwtObj.exp <= Math.floor(new Date().getTime() / 1000)) {
    console.log('this token was expired');
    const body = new URLSearchParams();
    body.append('grant_type', REFRESH_TOKEN_GRANT_TYPE);
    body.append('client_id', clientId);
    body.append('refresh_token', refreshToken);

    const resp = await fetch(`${idpUrl}oauth2/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body,
    });
    const tokenResp = resp.json();

    setTokenCookies(
      tokenResp.id_token,
      tokenResp.refresh_token,
      tokenResp.expires_in
    );
    return tokenResp.id_token;
  } else return jwt;
};

// this is the saml initiation. it will handle the token endpoint call if a code is present in the querystring.
// if no code it will start the intial login process with saml
// encodeURIComponent(state)
export async function samlInit(
  idpUrl,
  clientId,
  redirectUrl,
  provider,
  origin
) {
  // const cookies = new Cookies();
  // let token = cookies.get('id_token');

  const params = window.location.search;
  const path = window.location.pathname;
  const queryString = new URLSearchParams(params);
  const responseCode = queryString.get('code');
  if (!!responseCode) console.log('responseCode', responseCode);
  const stateParam = queryString.get('state');
  console.log('stateParam from response code', stateParam);

  try {
    const stateObj = JSON.parse(stateParam);
    console.log('parsed state object', stateObj);
    clientId = stateObj.client_id;
    redirectUrl = `${stateObj.redirect_url}`;
    origin = stateObj.origin;
  } catch (e) {
    console.log('error trying to parse state', e);
  }
  if (!!responseCode) {
    const tokenResp = await getTokenFromResponseCode(
      idpUrl,
      responseCode,
      redirectUrl,
      clientId
    );
    console.log('tokenResp from code', tokenResp);

    if (!!tokenResp.id_token) {
      console.log('LOOK ERE', `${origin}?token=`);
      const tokenStr = JSON.stringify(tokenResp);
      window.location = `${origin}?token=${encodeURIComponent(tokenStr)}`;
    } else {
      console.log('failed on token endpoint', tokenResp);
      window.location = `${origin}?error=${encodeURIComponent(
        'failed to get id token'
      )}`;
    }
  } else {
    redirectToLogin(idpUrl, clientId, redirectUrl, provider, path, origin);
  }
}
